import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import Seo from '../components/Seo'
import NavbarGlobal from '../components/NavbarGlobal'
import FooterGlobal from '../components/FooterGlobal'
import { FaCheckCircle } from "react-icons/fa"


export default function TreningMedyczny() {
  
  return (

    <div className='layout'>

      <Seo 
        title="Trening Medyczny"
        description="Trening osobisty dla żyjących z bólem kolan, pleców czy innych obszarów ciała, którzy chcą w bezpieczny sposób poprawić sprawność ruchową"
        keywords="trening medyczny, warszawa"
        url="https://www.fizjopersonalny.pl/trening-medyczny"
      />

      <div className='header_gradient'>
        <NavbarGlobal />
        <div className='offer_box'>

          <div className='offer_intro_header'>
            <h1>Trening medyczny</h1>
            <span>Bezpieczna aktywność pomimo ograniczeń</span>
            <p>Trening osobisty dla żyjących z bólem kolan, pleców czy innych obszarów ciała, którzy chcą w bezpieczny sposób poprawić sprawność ruchową.</p>
            <ul>
              <li><FaCheckCircle />modyfikacja ćwiczeń pod specjalne potrzeby</li>
              <li><FaCheckCircle />wsparcie fizjoterapeuty i trenera</li>
              <li><FaCheckCircle />indywidualny plan treningowy</li>
            </ul>
            <div className='offer_intro_buttons'>
              <Link to="/kontakt" className='button'>Umów wizytę</Link>
              <Link to="#wiecej" className='button offer_intro_button'>dowiedz się więcej</Link>
            </div>
          </div>

          <div className='offer_intro_image'>
            <StaticImage src="../images/offer/trening-medyczny.jpg" alt="Trening medyczny | Warszawa"/>
          </div>

        </div>
      </div>
      
      <div className='content'>
      
        <section id="wiecej">
          <div className='offer_content'>
            <h2>Trening medyczny usprawnia ciało pomimo ograniczeń</h2>
            <span>Podnieś poziom codziennej sprawności</span>
            <p>Ruch to zdrowie. Banał. Łatwo powiedzieć, ale trudniej zrobić. Szczególnie, gdy doskwiera ból kolana z powodu chondromalacji albo przeciążone pracą i stresem plecy. Ból, kontuzje, urazy ograniczają Twoją aktywność fizyczną, a Ty nie wiesz co należy, a czego nie można robić.</p>

            <div className='offer_client'>
              <ul>
                <li>
                  <h3>ból kolana</h3>
                  <StaticImage src="../images/offer/staw-kolanowy.jpg" alt="Ból kolana"/>
                </li>
                <li>
                  <h3>przeciążony kręgosłup</h3>
                  <StaticImage src="../images/offer/bol-odcinek-piersiowy.jpg" alt="Ból odcinka piersiowego kręgosłupa"/>
                </li>
                <li>
                  <h3>kontuzje i urazy</h3>
                  <StaticImage src="../images/offer/staw-skokowy.jpg" alt="Ból kostki"/>
                </li>
              </ul>
            </div>

            <p>Trening medyczny umożliwia zaprojektowanie zestawów ćwiczeń i ułożenie planu treningowego pod Twoje aktualne potrzeby ruchowe. Uwzględnia także przyszłe (w przypadku planowanych zabiegów i operacji) ograniczenia zdrowotne. Nie wszystko można wyleczyć, ale zawsze można się lepiej ruszać.</p>
            <div className='offer_button'>
              <Link to="/kontakt" className='button'>Umów wizytę</Link>
            </div>
          </div>
        </section>
      
        <section>
          <div className='offer_content offer_content_background'>
            <h2>Trening medyczny w opinii klientów</h2>
            <span>Rekomendacje osób, które zdecydowały się na współpracę</span>
            <p>Przedstawione poniżej rekomendacje stanowią część opinii, które możesz znaleźć w takich miejscach jak: <a href="https://goo.gl/maps/4H6dS7wtz8Ue3JSh9" target="_blank" rel="noreferrer">Google Maps</a>, <a href="https://www.facebook.com/FizjoPersonalny/" target="_blank" rel="noreferrer">Facebook</a>, <a href="https://www.instagram.com/FizjoPersonalny/" target="_blank" rel="noreferrer">Instagram</a> czy <a href="https://www.znanylekarz.pl/lukasz-kalenczuk/fizjoterapeuta/warszawa" target="_blank" rel="noreferrer">Znany Lekarz</a>.</p>

            <div className='offer_feed'>
              <ul>
                <li>
                  <StaticImage src="../images/feed/gm-1.png" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
                </li>
                <li>
                  <StaticImage src="../images/feed/fb-2.png" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
                  <StaticImage src="../images/feed/fb-6.png" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
                  <StaticImage src="../images/feed/fb-1.png" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
                </li>
                <li>
                  <StaticImage src="../images/feed/gm-3.png" alt="Fizjoterapia i Trening Personalny | Warszawa"/>
                </li>
              </ul>
            </div>

            <div className='offer_button'>
              <Link to="/kontakt" className='button'>Umów wizytę</Link>
            </div>
          </div>
        </section>
      
        <section>
          <div className='offer_content'>
            <h2>Trening medyczny utrwala zdrowe nawyki ruchowe</h2>
            <span>Aktywność fizyczna dopasowana do Twoich potrzeb</span>
            <p>Planowanie programu treningowego rozpoczynam od identyfikacji Twojego sposobu poruszania sie. Następnie modyfikuję ćwiczenia, uwzględniając aktualny poziom sprawności. Uczę Cię poprawnego ich wykonania. Ostatnim etapem jest dynamiczna korekcja ćwiczeń i utrwalenie prawidłowej techniki.</p>
            
            <div className='offer_fitness_protocol'>
              <ul>
                <li>
                  <h3>diagnostyka funkcjonalna</h3>
                  <StaticImage src="../images/offer/diagnostyka-funkcjonalna.jpg" alt="Badanie funkcjonalne"/>
                </li>
                <li>
                  <h3>dopasowanie ćwiczeń</h3>
                  <StaticImage src="../images/offer/modyfikacja-cwiczen.jpg" alt="Modyfikacje ćwiczeń"/>
                </li>
                <li>
                  <h3>utrwalenie techniki</h3>
                  <StaticImage src="../images/offer/dynamiczna-korekcja.jpg" alt="Dynamiczna korekcja ćwiczenia"/>
                </li>
              </ul>
            </div>

            <p>Trening medyczny stanowi łącznik pomiędzy fizjoterapią, a stanem optymalnej sprawności i wydolności organizmu. Pozwala wykryć dysfunkcje ruchowe oraz stwarza możliwości ich wyleczenia. Uczy poprawnego wykonywania ćwiczeń, dostosowując poziom trudności do Twoich możliwości.</p>
            <div className='offer_button'>
              <Link to="/kontakt" className='button'>Umów wizytę</Link>
            </div>
          </div>
        </section>

      </div>
      <FooterGlobal />
    </div>

  )
}